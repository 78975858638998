<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <sidebar
      :store-module-name="STORE_MODULE_NAME"
      :is-sidebar-active.sync="isSidebarActive"
      :show.sync="show"
      :data="sidebarData"
    />
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <b-row>
            <b-col>
              <label>{{ $t("Show") }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector v-select-custom"
                @input="pageSize"
              />
              <label for="">{{ $t("entries") }}</label>
            </b-col>

            <b-col
              cols="12"
              md="6"
              class="b-col-custom"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  :placeholder="$t('Search') + '...'"
                  @input="typing($event)"
                />
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="d-flex"
                  @click="sidebarAddNew()"
                >
                  <feather-icon icon="PlusIcon" />
                  <!-- <span class="align-middle">Add Users</span> -->
                </b-button>
                <!-- <b-form-file
                  @change="importFileJson"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  accept=".json"
                /> -->
              </div>
            </b-col>

          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label-cols="2"
                label-cols-lg="2"
                label="Import File :"
                label-for="input-lg"
              >
                <b-form-file
                  ref="uploadFileJson"
                  accept=".json, .csv, .xls"
                  class="mb-2"
                  @change="updateFiles"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-progress :max="max">
                <b-progress-bar :value="current">
                  <strong>{{ textDisplayProgress }}</strong>
                </b-progress-bar>
              </b-progress>
            </b-col>
          </b-row>
        </div>
        <b-table
          ref="refUserListTable"
          striped
          responsive
          class="position-relative"
          :items="respData"
          :fields="tableColumns"
          primary-key="_id"
          show-empty
          :empty-text="$t('No matching records found')"
          style="height: 60vh;"
        >
          <!-- Column: Images -->
          <template #cell(prefix)="data">
            <p>
              {{

                showFromCurrentLanguage(data.item.prefix)

              }}
            </p>
          </template>
          <template #cell(firstName)="data">
            <p>
              {{

                showFromCurrentLanguage(data.item.firstName)

              }}
            </p>
          </template>
          <template #cell(lastName)="data">
            <p>
              {{
                showFromCurrentLanguage(data.item.lastName)
              }}
            </p>
          </template>
          <template #cell(created)="data">
            <b-row>
              <p>{{ data.item.created | formatDateTime }}</p>
            </b-row>
          </template>
          <!-- Column: Action -->
          <template #cell(action)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="sidebarEdit(data.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t("Edit") }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteData(data.item._id)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t("Delete") }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span
                class="text-muted"
              >{{ currentPage * perPage - (perPage - 1) }} -
                {{
                  queriedItems - currentPage * perPage > 0
                    ? currentPage * perPage
                    : queriedItems
                }}
                {{ $t("of") }} {{ queriedItems }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="queriedItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @input="nextPage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BImg,
  BAvatar,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BDropdown,
  BOverlay,
  BCardText,
  BDropdownItem,
  BFormGroup,
  BFormFile,
  BProgress,
  BProgressBar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import perPageOptions from '@/perPageOptions'
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/store/services/eCommerce/employeeList'
import debounce from 'debounce'
import * as XLSX from 'xlsx-color'
import Sidebar from './DataSidebar.vue'

const STORE_MODULE_NAME = 'employeeList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    BFormInput,
    BButton,
    Sidebar,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BCardText,
    BFormGroup,
    BFormFile,
    BProgress,
    BProgressBar,
    // BFormFile,
    // BBadge,
    // BLink,
    // BMedia,
    // BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      avatarText,
      currentPage: 1,
      searchQuery: '',
      perPage: localStorage.getItem('itemsPerPage')
        ? localStorage.getItem('itemsPerPage')
        : 30,
      isSidebarActive: false,
      sidebarData: {},
      show: false,
      current: 0,
      max: 0,
      textDisplayProgress: '',
      perPageOptions,
    }
  },
  computed: {
    tableColumns() {
      return [
        { key: 'code', label: this.$t('Code'), sortable: false },
        { key: 'prefix', label: this.$t('Prefix'), sortable: false },
        { key: 'firstName', label: this.$t('FirstName'), sortable: false },
        { key: 'lastName', label: this.$t('LastName'), sortable: false },
        { key: 'created', label: this.$t('Create'), sortable: false },
        { key: 'action', label: this.$t('ACTION'), sortable: false },
      ]
    },
    respData() {
      return store.state[STORE_MODULE_NAME].respData != null
        ? store.state[STORE_MODULE_NAME].respData.data
        : []
    },
    queriedItems() {
      return store.state[STORE_MODULE_NAME].respData != null
        ? store.state[STORE_MODULE_NAME].respData.max
        : 0
    },
    wsClient() {
      return store.state.websockets.wsClient[STORE_MODULE_NAME]
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    this.get()
    this.connectWebsocket()
    this.typing = debounce(this.typing, 500)
  },
  beforeMount() {
    // WebSocket
    if (this.wsClient) {
      // ถ้าเกิดเชื่อมไม่ได้ จะเข้า onErrorก่อน หลังจากนั้นจะทำการ onClose เอง
      this.wsClient.onerror = err => {
        console.log('onError')
        console.log(err)
      }
      this.wsClient.onclose = err => {
        console.log('onClose')
        console.log(err)
      }
      this.wsClient.onmessage = msg => {
        const SocketMessage = JSON.parse(msg.data)
        //
        if (SocketMessage.data.send) {
          if (SocketMessage.data.send.page === 'EMPLOYEE') {
            const { data } = SocketMessage.data.send.data
            const employee = SocketMessage.data.send.data.data.data
            // console.log(data)
            const { max, current } = data
            this.current = current
            this.max = max
            this.textDisplayProgress = `${current} / ${max}`
            if (this.respData.length < this.perPage) {
              store.commit(`${STORE_MODULE_NAME}/UPDATE`, employee)
            }
          }
        }
      }
    }
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    store.dispatch('websockets/disconnect', STORE_MODULE_NAME)
  },
  setup() {
    return { STORE_MODULE_NAME }
  },
  methods: {
    connectWebsocket() {
      const userData = JSON.parse(localStorage.getItem('userData'))
      const applicationId = localStorage.getItem('applicationId')
      // WebSocket Connect
      const pathname = `/Members?type=${userData.userType}&userId=${userData.userId}&applicationId=${applicationId}`
      const params = {
        pathname,
        page: STORE_MODULE_NAME,
      }
      store.dispatch('websockets/connect', params)
    },
    updateFiles(input) {
      if (input.target.files && input.target.files[0]) {
        const arrName = input.target.files[0].name.split('.')
        const ext = arrName[arrName.length - 1]

        const reader = new FileReader()
        reader.onload = e => {
          if (ext === 'xlsx' || ext === 'xls') {
            this.excelImport(e.target.result)
          } else if (ext === 'csv') {
            this.csvImport(e.target.result)
          }
        }
        if (ext === 'xlsx' || ext === 'xls') {
          reader.readAsArrayBuffer(input.target.files[0])
        } else if (ext === 'csv') {
          reader.readAsText(input.target.files[0])
        }
      }
    },
    csvImport(input) {
      const lines = input.split('\n')
      const header = lines[0].split(',')
      const output = lines.slice(1).map(line => {
        const fields = line.split(',')
        return Object.fromEntries(header.map((h, i) => [h.trim(), fields[i]]))
      })
      const formatData = this.formatData(output)
      this.import(formatData)
    },
    excelImport(items) {
      const data = new Uint8Array(items)
      const workbook = XLSX.read(data, { type: 'array' })

      // เลือกเฉพาะ sheet แรก
      const sheetName = workbook.SheetNames[0]
      const sheet = workbook.Sheets[sheetName]

      // แปลง sheet เป็น JSON
      const jsonData = XLSX.utils.sheet_to_json(sheet)
      const formatData = this.formatData(jsonData)
      this.import(formatData)
    },
    formatData(data) {
      return data.map(item => ({
        code: item.EmpCode,
        prefix: [
          {
            lang: 'th',
            value: item.PersonPrefix,
          },
          {
            lang: 'en',
            value: item.PersonPrefixEng,
          },

        ],
        firstName: [
          {
            lang: 'th',
            value: item.PersonFNameThai,
          },
          {
            lang: 'en',
            value: item.PersonFNameEng,
          },
        ],
        lastName: [
          {
            lang: 'th',
            value: item.PersonLNameThai,
          },
          {
            lang: 'en',
            value: item.PersonLNameEng,
          },
        ],
      }))
    },
    import(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to import')} ${data.length} ${this.$t('lists')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'warning',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            store
              .dispatch(`${STORE_MODULE_NAME}/import`, { data })
            // eslint-disable-next-line no-unused-vars
              .then(result => {

                // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
              })
              .catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
              })
          }
        })
    },
    showFromCurrentLanguage(data) {
      if (data) {
        const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
        if (indexLang > -1) {
          return data[indexLang].value
        }
        return ''
      }
      return ''
    },
    get() {
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
        searchQuery: this.searchQuery,
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, obj)
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false

          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    typing(value) {
      console.log(value)
      this.searchQuery = value
      this.get()
    },
    sidebarAddNew() {
      this.sidebarData = {}
      this.isSidebarActive = true
    },
    sidebarEdit(item) {
      this.sidebarData = item
      this.isSidebarActive = true
    },
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to delete')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.show = true
            store
              .dispatch(`${STORE_MODULE_NAME}/delete`, { id })
              // eslint-disable-next-line no-unused-vars
              .then(result => {
                this.show = false
              })
              .catch(error => {
                this.show = false
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
              })
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        })
    },
    pageSize(size) {
      localStorage.setItem('itemsPerPage', size)
      this.currentPage = 1
      this.perPage = size
      this.get()
    },
    nextPage(page) {
      this.currentPage = page
      this.get()
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.b-col-custom {
  margin-top: 0.5rem;
}
</style>
