<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="val => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ Object.entries(data).length === 0 ? $t('Add New') : $t('Update') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- Form -->
      <validation-observer ref="formData">
        <b-form class="p-2">
          <b-row>
            <b-col
              cols="12"
            >
              <b-form-group
                :label="$t('Employee Code')"
                label-for="register-employeeCode"
              >
                <validation-provider
                  #default="{ errors }"
                  name="employeeCode"
                  vid="employeeCode"
                  rules=""
                >
                  <b-form-input
                    id="register-employeeCode"
                    v-model="code"
                    name="register-employeeCode"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('Employee Code')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('Prefix')"
                label-for="prefix"
              >
                <b-row>
                  <b-col
                    v-for="(item, index) in language"
                    :key="`${index}_prefix`"
                    cols="12"
                  >
                    <b-form-group
                      :label="$t(item.name)"
                      :label-for="item.name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="item.name"
                        :vid="item.name"
                      >
                        <b-form-input
                          v-if="prefix[index]"
                          :id="item.name"
                          :name="item.name"
                          :value="prefix[index]['value']"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t(item.name)"
                          @input="inputLanguage($event, item.lang, index, 'prefix')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('First name')"
                label-for="firstName"
              >
                <b-row>
                  <b-col
                    v-for="(item, index) in language"
                    :key="`${index}_firstName`"
                    cols="12"
                  >
                    <b-form-group
                      :label="$t(item.name)"
                      :label-for="item.name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="item.name"
                        :vid="item.name"
                      >
                        <b-form-input
                          v-if="firstName[index]"
                          :id="item.name"
                          :name="item.name"
                          :value="firstName[index]['value']"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t(item.name)"
                          @input="inputLanguage($event, item.lang, index, 'firstName')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('Last name')"
                label-for="lastName"
              >
                <b-row>
                  <b-col
                    v-for="(item, index) in language"
                    :key="`${index}_lastName`"
                    cols="12"
                  >
                    <b-form-group
                      :label="$t(item.name)"
                      :label-for="item.name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="item.name"
                        :vid="item.name"
                      >
                        <b-form-input
                          v-if="lastName[index]"
                          :id="item.name"
                          :name="item.name"
                          :value="lastName[index]['value']"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t(item.name)"
                          @input="inputLanguage($event, item.lang, index, 'lastName')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Action button -->
          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              @click="validationForm()"
            >
              {{ $t('Submit') }}
            </b-button>
            <b-button
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BSidebar,
  BButton,
  BFormFile,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BCardText,
  BInputGroupAppend,
  BInputGroup,
  BFormInput,
  BForm,
  BFormGroup,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import store from '@/store'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    BSidebar,
    BButton,
    BFormFile,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BCardText,
    BForm,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    flatPickr,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroupAppend,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    Cleave,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      dataId: null,
      optionCleave: {
        number: {
          numeral: true,
        },
      },
      code: '',
      prefix: [],
      firstName: [],
      lastName: [],
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    language() {
      return store.state.appConfig.systemConfig
        ? store.state.appConfig.systemConfig.language
        : [];
    }
  },
  watch: {
    isSidebarActive(val) {
      if (val) {
        if (Object.entries(this.data).length === 0) {
          this.$refs.formData.reset()
          this.initValues()

          if (this.prefix.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.prefix.findIndex(
                element => element.lang === this.language[index].lang,
              )
              console.log(indexLanguage)

              if (indexLanguage < 0) {
                this.prefix.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
          }
          if (this.firstName.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.firstName.findIndex(
                element => element.lang === this.language[index].lang,
              )
              console.log(indexLanguage)

              if (indexLanguage < 0) {
                this.firstName.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
          }
          if (this.lastName.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.lastName.findIndex(
                element => element.lang === this.language[index].lang,
              )
              console.log(indexLanguage)

              if (indexLanguage < 0) {
                this.lastName.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
          }
        } else {
          const {
            _id,
            code,
            prefix,
            firstName,
            lastName,
          } = this.data
          this.dataId = _id
          this.code = code
          this.prefix = prefix
          if (this.prefix.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.prefix.findIndex(
                element => element.lang === this.language[index].lang,
              )
              if (indexLanguage < 0) {
                this.prefix.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
            for (let index = 0; index < this.prefix.length; index += 1) {
              const indexLanguage = this.language.findIndex(
                element => element.lang === this.prefix[index].lang,
              )
              if (indexLanguage < 0) {
                this.prefix.splice(index, 1)
              }
            }
          }
          this.firstName = firstName
          if (this.firstName.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.firstName.findIndex(
                element => element.lang === this.language[index].lang,
              )
              if (indexLanguage < 0) {
                this.firstName.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
            for (let index = 0; index < this.firstName.length; index += 1) {
              const indexLanguage = this.language.findIndex(
                element => element.lang === this.firstName[index].lang,
              )
              if (indexLanguage < 0) {
                this.firstName.splice(index, 1)
              }
            }
          }
          this.lastName = lastName
          if (this.lastName.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.lastName.findIndex(
                element => element.lang === this.language[index].lang,
              )
              if (indexLanguage < 0) {
                this.lastName.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
            for (let index = 0; index < this.lastName.length; index += 1) {
              const indexLanguage = this.language.findIndex(
                element => element.lang === this.lastName[index].lang,
              )
              if (indexLanguage < 0) {
                this.lastName.splice(index, 1)
              }
            }
          }
        }
      }
    },
  },
  methods: {
    inputLanguage(input, lang, index, type) {
      if (type === 'prefix') {
        if (this.prefix[index].lang === lang) {
          this.prefix[index].value = input
        }
      } else if (type === 'firstName') {
        if (this.firstName[index].lang === lang) {
          this.firstName[index].value = input
        }
      } else if (type === 'lastName') {
        if (this.lastName[index].lang === lang) {
          this.lastName[index].value = input
        }
      }
    },
    initValues() {
      this.dataId = null
      this.code = ''
      this.prefix = []
      this.firstName = []
      this.lastName = []
    },
    validationForm() {
      this.$refs.formData.validate().then(success => {
        if (success) {
          const obj = {
            id: this.dataId,
            code: this.code,
            prefix: this.prefix,
            firstName: this.firstName,
            lastName: this.lastName,
          }
          this.$emit('update:show', true)
          if (this.dataId !== null) {
            store
              .dispatch(`${this.storeModuleName}/update`, obj)
              // eslint-disable-next-line no-unused-vars
              .then(result => {
                this.$emit('update:is-sidebar-active', false)
                this.$emit('update:show', false)

                // this.showToast('success', 'bottom-right')
              })
              .catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                this.$emit('update:show', false)
                // this.showToast('danger', 'bottom-right')
              })
          } else {
            delete obj.id
            store
              .dispatch(`${this.storeModuleName}/add`, obj)
              // eslint-disable-next-line no-unused-vars
              .then(result => {
                this.$emit('update:is-sidebar-active', false)
                this.$emit('update:show', false)

                // this.showToast('success', 'bottom-right')
              })
              .catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                this.$emit('update:show', false)
                // this.showToast('danger', 'bottom-right')
              })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
